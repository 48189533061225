.add-listing-btn,
.lostpassword-button {
    padding: 15px 100px;
    width: 100%;
    color: $white;
    font-size: 20px;
    margin-top: 35px;
		text-align:center;
		border-radius: 4px;
    border-width: 2px;
    border: solid $brand-primary;
    background: $blue-dark !important;
		
		&:hover {
				background: $brand-primary !important;
				color: $white;
				text-decoration:none;
				border: solid $gray-dark;
				transition:ease .5s;
		}
}

.gform_page_footer .button.gform_previous_button,
.gform_wrapper .gform_page_footer .button.gform_next_button,
.gform_wrapper .gform_page_footer input[type=submit],
.gform_button {
		border-radius: 4px;
    border-width: 2px;
    border: solid $brand-primary;
    background: $blue-dark;   	
		padding: 10px 50px;
    width: 48%;
    float: left;
    color: #fff;
    font-size: 20px !important;
    text-align: center;
    margin-bottom: 35px;
		
		&:hover {
				background: $brand-primary;
				color: $white;
				text-decoration:none;
				border: solid $gray-dark;
				transition:ease .5s;
		}
}
.add-listing-table {
	.link {
		a {
				padding: 3px 25px;
				border-radius: 4px;
				border-width: 2px;
				border: solid $brand-primary;
				background: $blue-dark;
				width: 100%;
				color: #fff;
				font-size: 13px;
				text-align: center;
				margin: 0px 0 0 -9px;
				float: left;
			
			&:hover {
				background: $brand-primary;
				color: $white;
				text-decoration:none;
				border: solid $gray-dark;
				transition:ease .5s;
			}
		}
	}
}