// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

/*********************
Claim Listing
*********************/
#claim_listing_form {
		
	input#user_login {
		border: none;
		width: 100%;
		font-weight: bold;
		color:$brand-highlight;
	}
	input.lostpassword-button {
    width: 100%;
    padding: 1em;
    background: $brand-primary;
    border: 1px solid $gray;
    border-radius: 6px;
    color: $white;
    font-weight: bold;
	}
	input.lostpassword-button:hover {
    background: $brand-highlight;
	}
}

/*********************
Gravity Forms
*********************/
body .gform_wrapper .gform_body .gform_fields .gfield input[type=text],
body .gform_wrapper .gform_body .gform_fields .gfield select,
body .gform_wrapper .gform_body .gform_fields .gfield textarea,
body .gform_wrapper .gform_body .gform_fields .gfield .gfield_radio li input[type=radio] {
	border:1px solid $blue-dark;
	border-radius:4px;
	background:$gray-lightest;
	color:$gray-dark;
	padding:10px 5px !important;
	font-size:18px !important;
	
	&focus,
	&hover {
		border-color:$gray-dark;
	}
}
#field_1_5 label {
	display:none !important;
}
body .gform_wrapper .top_label div.ginput_container {
	margin-top: 0 !important;
}

.gform_wrapper .field_sublabel_above .description, 
.gform_wrapper .field_sublabel_above .gfield_description, 
.gform_wrapper .field_sublabel_above .gsection_description,
.gform_wrapper .description_above .gfield_description {
	margin-top: 20px !important;
	font-weight: bold;
	color: $blue-dark;
	padding: 0 0 0 !important;
}
body .gform_wrapper ul li.gfield {
	margin-top: 0 !important;
}
.gform_wrapper h1, .gform_wrapper h2, .gform_wrapper h3 {
	color: #444;
}
.gform_wrapper li.hidden_label input {
	margin-top: 0 !important;
}
.gform_wrapper div.validation_error {
	color: $brand-highlight !important;
	border-top: 2px solid $brand-highlight !important;
	border-bottom: 2px solid $brand-highlight !important;
}
.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
	background-color: $white !important;
	border-top: none !important;
	border-bottom: 4px solid $brand-highlight !important;
	color: $white !important;
}
.gform_wrapper .validation_message {
	color: $brand-highlight !important;
	font-size:12px;
}
.gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea {
    border: 1px solid $brand-highlight !important;
}
 select {
    height: 47px;
    margin-top: 0 !important;
}
.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], 
.gform_wrapper .gform_page_footer input[type=submit] {
    width:99%;
}
.vc_btn3.vc_btn3-color-juicy-pink, .vc_btn3.vc_btn3-color-juicy-pink.vc_btn3-style-flat {
    border-radius: 4px!important;
    border-width: 2px!important;
    border-color: #276db5!important;
    background: #195796!important;
    text-align: center!important;
}
.vc_btn3.vc_btn3-color-juicy-pink.vc_btn3-style-flat:focus, .vc_btn3.vc_btn3-color-juicy-pink.vc_btn3-style-flat:hover, .vc_btn3.vc_btn3-color-juicy-pink:focus, .vc_btn3.vc_btn3-color-juicy-pink:hover {
    background: #276db5!important;
    color: #fff!important;
    text-decoration: none!important;
    border-color: #333!important;
}
#input_1_5_5_container {
    width:101%;
}
.gform_wrapper li.field_sublabel_above .ginput_complex input[type=text] {
    margin-bottom: 15px !important;
}