/*****
Location Page
******/

.location_description,
.location_media {
	h2,
	h3,
	h4 {
		color:$brand-primary;
	}
	h2 {
		border-bottom:$brand-highlight solid 2px;
	}
}
.location_details {
	background:$gray-lightest;
	color:$gray; 
	padding:1px 20px 35px 10px;
	margin: -30px 0;
	
	h3 {
		color:$brand-highlight;
		margin-top:20px;
	}
	.location_stats {
		margin-bottom:25px;
	}
	
	.detail_row {
		margin: 3px 0 0;
		border-bottom:#ccc 1px solid;
		padding: 5px 0;
		
		.detail_label {
			padding-left: 0px;
			font-weight:bold;
			text-transform:uppercase;
		}
	}
}
.location-map {
	margin-top: 35px;
}
.adsense-text {
	float:left;
}