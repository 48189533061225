// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Colors
$brand-primary:       #031150;
$brand-highlight:     #276db5;
$blue-dark:						#195796; 
$blue:								#195796;
$orange:							#f5921e;

$black:								#000000;
$white:								#ffffff;

$gray:                #666;
$gray-darkest:				#1C1C1C;
$gray-darker:         #222;
$gray-dark:           #333;
$gray-light:					#a6a6a6;
$gray-lighter:        #e7e7e7;
$gray-lightest:    		#f8f8f8;

//Links
$link-color:					$brand-primary;
$link-hover-color:		$brand-primary;	
$link-visited-color:	$brand-primary;
$link-active-color:		$brand-primary;	

//Bootstrap Overrides


// Navbar links
$navbar-default-link-color:                $gray;
$navbar-default-link-hover-color:          $brand-primary;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         $brand-primary;
$navbar-default-link-active-bg:            transparent !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

//Slick Overrides
$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";