/*****
General
******/
.sponsor-wrap {
    padding: 15px 0 20px !important;
		margin-top: 50px;
}
.sponsor-wrap h1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
    color: $blue-dark;
    text-transform: uppercase;
    text-shadow: 2px 2px 1px $white;
}
.bottom-table {
	.vc_column-inner {
		margin-top:0px;
		padding-top:0 !important;
	}
}
/*****
Premium
******/
.premium-image img, 
.featured-image img {
    height:75px;
    width:auto !important;
}
.premiere-row {
    background: $blue-dark;
}
.premiere-row  .gv-list-view {
    border: none;
}
.premiere-row .wpb_text_column {
    margin-top:35px;
}
.premiere-row  .gv-list-view-content {
    text-align:center;
    background:#eee;
    min-height:275px;
    border:3px solid $brand-highlight;
}
.premiere-row  .gv-list-view-content .premium-image img { 
    margin: 0 auto;
}
.premiere-row .wpb_text_column :last-child {
    padding-right: 0;
}
.premiere-row  .premium-name {
    font-weight:bold;
    font-size:16px;
}

/*****
Featured
******/
.featured-company .gv-list-view-content {
    background: #eee;
    border: $brand-highlight 3px solid;
}
.featured-company .featured-name {
    font-weight:bold;
    font-size:16px;
}
/*****
Standard
******/
.gv-table-view th.standard-name, .gv-table-view th.standard-address, .gv-table-view th.standard-phone, .gv-table-view th.standard-link {
    font-weight:bold;
    font-size:16px;
    color:$white;
}
.gv-table-view th {
    background: $blue-dark;
    padding:10px 5px !important
}
.standard-table td {
    padding: 10px 5px !important;
}

.standard-table tr:nth-child(even) {
    background-color: $gray-lighter
}
.standard-table tr:nth-child(odd) {
    background-color: $gray-lightest;
}
.standard-table .standard-name img {
    max-width: 250px;
    max-height: 100px;
}
.standard-table .standard-name {
    font-weight:bold;
}
.standard-table {

	.wpb_content_element {
		margin-bottom:0 !important;
	}
}

/*****
Basic
******/
.basic-table td {
    padding:0px 5px !important
}
.basic-table tr:nth-child(even) {
    background-color: $gray-lighter;
}
.basic-table tr:nth-child(odd) {
    background-color: $gray-lightest;
}
.basic-name, .standard-name {
    width: 35% !important;
}

/*****
Edit Page
******/
.gv-table-view th {
    background: $brand-primary;
    padding: 10px 5px !important;
    color: $white;
}

/*****
Add Listing Page
******/
.add-listing-table {
    width:100%;
    font-weight:normal;
}
.add-listing-table th {
    background:#333;
    color:$white;
    font-weight:bold;
    padding-left:5px;
}
.add-listing-table tr:nth-child(even) {
    background-color: #f8f8f8;
}
.add-listing-table tr:nth-child(odd) {
    background-color: #f1f1f1;
}

.add-listing-table tr.state-row {
    background-color: #e7e7e7;
    color:#276db5;
    margin-bottom:20px;
}
.add-listing-table .state, .add-listing-table .city  {
    padding-left:5px;
}
.state-row .state {
    padding: 7px 5px;
}
.city {
    padding-top: 3px;
    padding-bottom: 3px;
}
.add-listing-table .state  {
    font-weight:bold;
}
tr.empty-row {
    background-color: $white !important;
    height: 25px !important;
}

.add-listing-table .link{
    padding: 10px 0;
}


/*****
Members Area
******/
.member-area-menu {
	height: 100%;
	min-height: 375px;
	background: $gray-lighter;
	color: $blue-dark;
	padding: 1px 10px;
		
	.menu {
		padding: 0;
		
		li {
			list-style:none;
		
			a {
				color: $gray-dark;
				display: block;
				font-size:17px;
				
				&:hover {
					color:$brand-highlight;
					text-decoration:none;
				}
			}
		}
	}
}

.transaction-table-single {
		
	th {
		background: $white;
		color: $gray-dark;
		min-width: 250px;
	}
}
	
	
/*****
Blog
******/
#comments {
	width:100%;
	float:left;
	padding-top: 50px;
	border-top: 2px solid $brand-primary;
	margin-top: 100px;
}