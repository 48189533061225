/*****
Region Pages
******/
.grid-item { 
  float: left;
  height: 300px;
	margin-bottom:20px;
		
	&:after {
		content: '';
		display: block;
		clear: both;
	}
	
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 650px) { 
	.grid-item,
	.grid-sizer {
		width: 100%;
		text-align:center;
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 650px) { 
	.grid-item,
	.grid-sizer {
		width: 50%;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.grid-item,
	.grid-sizer {
		width: 33.33%;
	}
}



.region-location {
	.image { 
		img {
			border:5px solid $brand-primary;
		}
	}
	.title {
    max-width: 300px;
    background: $brand-primary;
    padding: 2px 10px;
    color: $white;
		font-weight:bold;
    text-align: center;
		a {
			color:$white;
			display:block;
		}
	}
}