/*****
Archive Pages
******/

.archive-item {
	margin-bottom:25px;
	
	h2 {
		margin-top:0;
	}
		
}